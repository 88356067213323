
body {
  background-color: whitesmoke;
}

.btn-back{
  color:#ea7927;
  background:none;
  border:none;
  padding-left: 30px;
  position: relative;
  &:before{
    content:'';
    display: block;
    width: 20px;
    height: 20px;
    background-image: url('./img/long-arrow-left-light.svg');
    background-repeat: no-repeat;
    position: absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
  }
  &:disabled{
    color:grey;
    cursor:none;
    pointer-events: none;
  }
}

.btn-purple {
  background-color: white;
  color: #48328a;
  border-radius: 10px;
  padding: 20px 40px;
  font-size: 20px;
  -webkit-box-shadow: 0px 0px 82px -27px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 82px -27px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 82px -27px rgba(0, 0, 0, 0.75);
  border:none;
  &:hover {
    backface-visibility: hidden;
    transition: all 0.2s ease-in-out;
    transform: scale(0.94);
    background-color: #48328a;
    color: white;
    text-decoration: none;
  }

  &:active {
    border: none;
  }

}

.progressbar {
      counter-reset: step;
      width: 700px;
      margin:0 auto;
  }
  .progressbar li {
      list-style-type: none;
      width: 20%;
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #7d7d7d;
  }
  .progressbar li:before {
      width: 30px;
      height: 30px;
      content: counter(step);
      counter-increment: step;
      line-height: 27px;
      border: 2px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
  }
  .progressbar li:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 15px;
      left: -50%;
      z-index: -1;
  }
  .progressbar li:first-child:after {
      content: none;
  }
  .progressbar li.active {
      color: #48297E;
      font-weight: bold;
  }
  .progressbar li.active:before {
      border-color: #48297E;
      background-color: #48297E;
      color:#fff;
  }
  .progressbar li.active + li:after {
      background-color: #48297E;
  }
  .progressbar li:last-child.active:before {
      background-color: green;
      border-color: green;
  }
  .progressbar li:last-child.active {
      color: green;
  }


.loader-wrapper{
  width: 100vw;
  img{
    display: block;
    width: 200px;
    margin: 0 auto;
  }
}
