.blik{
  float:left;
  width: calc(20% - 35px);
  a{
    &:hover{
      text-decoration: none;
      h4{
        background: #48328a;
        color:#fff;
      }
    }
  }
  .blik-inner-wrapper{
      box-shadow: 2px 2px 10px #777777;
      img{
        height: 280px;
        width: 100%;
          object-fit: cover;
      }
      h4{
        color:#48328a;
        font-size: 18px;
        padding:20px;
        height: 70px;
        text-align: center;
        background:#fff;
        transition: all 0.3s ease-in;
        &:hover{
          background: #48328a;
          color:#fff;
        }
      }
  }
}

@media (max-width: 1499px) {
    .blik {
        width: inherit !important;
        max-width: 20% !important;
        flex: inherit !important;
    }
}

@media (max-width: 1199px) {
    .blik {
        width: inherit !important;
        max-width: 25% !important;
        flex: inherit !important;
    }
}

@media (max-width: 991px) {
    .blik {
        width: inherit !important;
        max-width: 33.3333333333% !important;
        flex: inherit !important;
    }
}

@media (max-width: 767px) {
    .blik {
        width: inherit !important;
        max-width: 50% !important;
        flex: inherit !important;
    }
}

@media (max-width: 499px) {
    .blik {
        width: inherit !important;
        max-width: 100% !important;
        flex: inherit !important;
    }
}
